<template>
<div>
  <b-container>
      <label for="input-live">name</label>
      <b-form-input
          id="input-live"
          v-model="name"
          :state="nameState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter Your Name"
          trim
      ></b-form-input>

      <div style="text-align: center">
        <b-button class="w-50 mx-auto mt-3" @click="modifyName()">提交</b-button>
      </div>
  </b-container>
</div>
</template>

<script>
import { changeName, changePassword } from '@/api'

export default {
  name: "ModifyName",

  computed: {
    nameState () {
      return this.name.length > 4 && this.name.length < 16
    }
  },
  data () {
    return {
      staff: {},
      name
    }
  },
  mounted () {
    this.staff = JSON.parse(localStorage.getItem('staffInfo'))
  },
  methods: {
    modifyName () {
        changeName(this.name).then(res => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.$router.push({ path: '/welcome' })
          } else {
            this.$message.error(res.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
